import * as React from "react";
import { PageProps, graphql } from "gatsby";
import { MarkdownRemark } from "../../types";

export const BlogPost = ({
  data,
}: PageProps<{ markdownRemark: MarkdownRemark }>) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <div className="max-w-3xl m-auto">
      <div className="prose prose-lg w-full prose-purple">
        <h1>{frontmatter.title}</h1>
        <div>{frontmatter.date}</div>
        <div>{frontmatter.author}</div>
        <div>{frontmatter.tags.join(",")}</div>
        <article dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  );
};

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        author
        tags
      }
    }
  }
`;

export default BlogPost;
